<template>

    <!-- <div :id="id" :vmodel="id" :class="[(this.index <= this.interests) ? colorborder : 'dot' , (this.index <= (this.competence)) ? colorfill : 'dot' ]"> {{this.index}} </div>  -->

   <svg viewBox="0 0 80 80" width="19px" >
        <defs>
          <linearGradient gradientTransform="rotate(-10)" id="half_grad_0">
            <stop offset="50%" stop-color="#f07440"/>
            <stop offset="50%" stop-color="lightgrey" stop-opacity="1" />
          </linearGradient> <!-- url(#half_grad_0)-->
          <linearGradient gradientTransform="rotate(-10)" id="half_grad_1">
            <stop offset="50%" stop-color="#3399ff"/>
            <stop offset="50%" stop-color="lightgrey" stop-opacity="1" />
          </linearGradient> <!-- url(#half_grad_0)-->
          <linearGradient gradientTransform="rotate(-10)" id="half_grad_2">
            <stop offset="50%" stop-color="#74ba57"/>
            <stop offset="50%" stop-color="lightgrey" stop-opacity="1" />
          </linearGradient> <!-- url(#half_grad_0)-->
        </defs>
        <!-- <path v-if="(this.index > this.interests) | (this.index > this.competence)  " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='grey' />  -->
        <path  @click="onClick()" v-if="(this.index > this.interests) | (this.index > this.competence)  " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="lightgrey" stroke='grey' stroke-width="2"/>

      <path   @click="onClick()" v-if="(this.index <= this.interests)  && this.index <= this.competence && this.branch == 0 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#f07440" stroke="#70361d" stroke-width=10 />
      <path  @click="onClick()" v-if="(this.index <= this.competence)  && this.index > this.interests && this.branch == 0" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#f07440" stroke="#f07440" stroke-width=10 />
      <!-- <path v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 0)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='grey' stroke='#70361d' stroke-width=10 />    -->
      <path  @click="onClick()" v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 0)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='lightgrey' stroke='#70361d' stroke-width=10 />
      <path  @click="onClick()" v-if="(this.index - this.competence === 0.5) && this.branch == 0 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='url(#half_grad_0)' stroke='url(#half_grad_0)' stroke-width=10 />


      <path   @click="onClick()" v-if="(this.index <= this.interests)  && this.index <= this.competence && this.branch == 1 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#3399ff" stroke="#17416b" stroke-width=10 />
      <path   @click="onClick()" v-if="(this.index <= this.competence)  && this.index > this.interests && this.branch == 1" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#3399ff" stroke="#3399ff" stroke-width=10 />
      <!-- <path  v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 1)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='grey' stroke='#17416b' stroke-width=10 />    -->
              <path  v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 1)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='lightgrey' stroke='#17416b' stroke-width=10 />
      <path  @click="onClick()" v-if="(this.index - this.competence === 0.5) && this.branch == 1" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='url(#half_grad_1)' stroke='url(#half_grad_1)' stroke-width=10 />


      <path  @click="onClick()" v-if="(this.index <= this.interests)  && this.index <= this.competence && this.branch == 3 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#74ba57" stroke="#1c3113" stroke-width=10 />
      <path  @click="onClick()" v-if="(this.index <= this.competence)  && this.index > this.interests && this.branch == 3" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#74ba57" stroke="#74ba57" stroke-width=10 />
      <!-- <path v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 3)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='grey' stroke='#1c3113' stroke-width=10 />    -->
      <path  @click="onClick()" v-if="(this.index <= (this.interests) && this.index > this.competence && this.branch == 3)" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='lightgrey' stroke='#1c3113' stroke-width=10 />
      <path  @click="onClick()" v-if="(this.index - this.competence === 0.5) && this.branch == 3"  transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='url(#half_grad_2)' stroke='url(#half_grad_2)' stroke-width=10 />



      <path  @click="onClick()" v-if="(this.index <= this.competence) && this.branch == 2" transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill="#00a39e" stroke="#00a39e" stroke-width=10 />

      <!-- <path  @click="onClick()" v-if="(this.index > this.competence) && this.branch ==5 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='grey' /> -->
      <path  @click="onClick()" v-if="(this.index > this.competence) && this.branch ==5 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='white' stroke="#950000" stroke-width="3" />

      <path  @click="onClick()" v-if="(this.index <= this.competence) && this.branch ==5 " transform="rotate(25)" d="m20,5 18,-18 28,0 18,18 0,28 -18,18 -28,0 -18,-18z" fill='#950000' />

  </svg>
    <!-- <div v-if="isUserProfile()" :id="id" :vmodel="id" :class="[(this.index <= this.interests) ? colorborder : 'dot' , (this.index <= (this.competence)) ? colorfill : 'dot' ]"> </div>
    <div v-else @click="onClick()" :class="[(this.index <= (this.competence) ) ? colorfill: 'dot']" /> -->

    <!--
        -->
</template>

<script>
//
export default{
    name: "Dot",
    props:['title', 'branch', 'id',  'index', 'competence', 'interests'],

    data() {
        return {
            clicked: false,
        }
    },

    //computed: mapGetters(['getProfile', 'getProfileType']),

    methods: {


        onClick() {
          this.$emit('clickedDot', this.title);
        },

    },
    updated(){
      //console.log("Rerendering", this.branch)
    },
}
</script>

<style lang="scss" scoped>

.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border: 3px solid #bbb;
  border-radius: 50%;
  display: inline-block;
}

.bluedot {
  height: 15px;
  width: 15px;
  background-color: #3399ff;
  border: 3px solid  #3399ff;
  border-radius: 50%;
  display: inline-block;
}

.blueborder {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid  #17416b;
  display: inline-block;
}

.orangedot {
  height: 15px;
  width: 15px;
  background-color: #f07440;
  border: 3px solid  #f07440;
  border-radius: 50%;
  display: inline-block;
}

.orangeborder {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid  #70361d;
  display: inline-block;
}

.greendot {
  height: 15px;
  width: 15px;
  background-color: #74ba57;
  border: 3px solid   #74ba57;
  border-radius: 50%;
  display: inline-block;
}

.greenborder {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid  #1c3113;
  display: inline-block;
}

.darkbluedot {
  height: 15px;
  width: 15px;
  background-color: #00a39e;
  border: 3px solid   #00a39e;
  border-radius: 50%;
  display: inline-block;
}

.darkblueborder {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 3px solid  #000e0d;
  display: inline-block;
}
</style>
