<template>
  <div class="page container full is-vcentered">
    <section class="section">
      <div class="columns is-vcentered">

        <div class="column is-6-tablet is-4-desktop">

          <div class="columns left is-mobile">
            <div class="column">
              <Dot style="width: 12px" :branch="0" :competence="1" :interests="0" :index="1"/>
              Können
            </div>

            <div class="column">
              <Dot style="width: 12px" :branch="0" :competence="0" :interests="1" :index="1"/>
              Interesse
            </div>

          </div>

          <br/>
          <div v-for="(n, questionIndex) in questions" :key="questionIndex">
            <DotSequence :type="'code'" :intermediate="true" :showConcept="false" :indexQuery="questionIndex" :title="n.title" :branch="0" :competence="n.answ2" :interests="n.answ1" :length="7"/>
          </div>
          <div>

          </div>
        </div>

        <div class="column left">
          <h2 class="a__item "> Von Deinem Aufgabenprofil zum Schnuppermatching </h2>
          <p>Hier siehst Du Dein Aufgabenprofil. Im Schnuppermatching kannst Du es beispielhaft mit einigen Referenzprofilen matchen lassen. Das zeigt Dir, wie der Entfalter in seinen Vollversionen Dich mit über 200 repräsentativen Berufs-, Studien- und anderen Tätigkeitsprofilen in Beziehung setzt, sodass Du Deine Passungen zu diesen Profilen einschätzen kannst. Damit wir Dir Dein Schnuppermatching zusenden können, hinterlasse uns bitte hier Deine E-Mail-Adresse.
          </p>
          <br/>
          <input style="width: 100%" placeholder="Emailadresse" v-model="mail"/> {{ mail }}
          <br/>
          <br/>
          <label for="check-News" v-if="!requestSend"> <input type="checkbox" id='check-News' v-model="checkNews" value="0"/> Ich möchte über Neuigkeiten auf dem Laufenden gehalten werden.
          </label>
          <div class="message success" v-if="requestSend"> Dein Matching ist auf dem Weg zu Dir. Sieh in Deinem E-Mail-Postfach nach. </div>
          <div class="message error" v-if="missingMail"> Trage bitte eine E-Mailadresse ein. </div>
          <!-- <div class="message error" v-if="randomError"> Da lief etwas schief, probiere es erneut oder melde dich bei uns. </div>              -->
        </div>

      </div>
      <EntfalterButton v-if="requestSend" @click="$router.push({ path: '/demo' })"
                       style="text-align: right">
        Nochmal beginnen
      </EntfalterButton>


      <EntfalterButton v-if="!requestSend" @click="requestMatching()" style="text-align: left">
        Schnuppermatching
      </EntfalterButton>
    </section>
  </div>
</template>

<script>
import DotSequence     from "../components/DotSequence.vue";
import Dot             from "../components/Dot.vue";
import EntfalterButton from "../components/entfalter-button.component";
import axios from 'axios';



export default {
  name: "Match",
  data() {
    return {
      questions: JSON.parse(localStorage.getItem("storedData")),
      checkNews: 0,
      mail: "",
      tempCode: '',
      id : '',
      requestSend: false,
      missingMail: false

    }
  },
  components: {
    EntfalterButton,
    DotSequence,
    Dot,

  },

  methods: {
    async sendTrail(){
       let uriStr = ''
      // question.id_0_question.answer1Given;question.id_1_question.answer2Given;
      this.questions.map((question) => {
         uriStr +=  question.id + '_0_' + (question.answ1 || null) + ';' + question.id + '_1_' + (question.answ2 || null) + ';'
      })

      await axios.post(process.env.VUE_APP_API_URL + `trialCode&userMail=${this.mail}&news=${this.checkNews === true ? 1 : 0}&userAnswers=${uriStr}`)
          .then(response => {
            // Do this silently
            this.tempCode = response.data.codeKey
            this.id = response.data.codeId
            })
    },

    async requestMatching(){
      if(this.mail.length > 0 ){

        const trail = async () => {
            return await this.sendTrail();
          };

          trail().then(()=> {
            axios.post(process.env.VUE_APP_API_URL + `trialResultRequest&codeId=${this.id}&codeKey=${this.tempCode}`)
            .then(response => {
              if (response.data.result === 1){
                this.requestSend = true
                this.missingMail = false
                //this.randomError = false

              } else {
                //this.randomError = true
                this.missingMail = true
                this.requestSend = false
             }
            })
          })
      } else {
        this.missingMail = true
        this.requestSend = false

      }

     }
  }
}

</script>

<style lang="scss" scoped>

label {
  display : inline-block;
  width   : 140px;
  &[for="check-News"] {
    width : auto;
  }
}

td {
  padding-right : 80px
}
</style>
