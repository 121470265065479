<template>
	<div class='columns is-mobile dotsequence'>
		<!-- Können / Interesse für Intermediate Result -->
		<div :class="['column left pb-0', title.length ? 'is-6' : 'is-full']">
			<Dot style="margin-left: 0px;margin-bottom: 2px;" :branch="branch" :id="id" :vmodel="id" :title="title+'_'+index" :index="index" :competence="competence" :interests="interests" v-for="(index) in length" :key="index"/>
		</div>
		<div v-if="title.length" class='column is-6 left' style="padding-bottom: 0px">
			<span>{{ title }}</span>
		</div>
	</div>
</template>

<script>
import Dot from './Dot.vue';


export default {
	name:       "DotSequence",
	components: {
		Dot,
	},

	// TODO use Type = 'user' (Können und Interesse) / type = 'profile' (Können, clickbar) / type = 'concept' (Können und Interesse und Konzept(clickbar) ) / type = 'matching' (nur Können)
	props: ['branch', 'title', 'content', 'competence', 'interests', 'length', 'id', 'index', 'concept', 'underline', 'showConcept', 'type', 'intermediate'],

	data() {
		return {
			amount_concept:    this.concept - 1,
			amount_competence: this.competence,
		}
	},


	methods: {
		onClick(title) {

			const selectedCircle = parseInt(title.split("_")[title.split("_").length - 1])

			// if (this.mode.code.state != 7 && this.type ==="user"){
			//      this.updateAmount(selectedCircle)

			// }

			// if (this.type ==="profile"){
			//    this.updateAmount(selectedCircle)
			// }


			if (this.type === "code") {
				if (this.mode.code.state != 7) {
					this.updateAmount(selectedCircle)
				}
				const clickEvent = {
					// amount_concept: this.amount_concept,
					// topicId: this.title.split("_")[0],
					// topicIndex: this.title.split("_")[1],
					// topicTitle: this.title.split("_")[2],
					// title: this.title.split("_")[3]
					id:        this.title.split("_")[0],
					answEval1: -1,
					answEval2: this.amount_concept + 1,
					title:     this.title.split("_")[1]
				}
				this.$emit('clickedDotSeq', clickEvent)
			} else {
				this.updateAmount(selectedCircle)

				const clickEvent = {
					// amount_concept: this.amount_concept,
					// topicId: this.title.split("_")[0],
					// topicIndex: this.title.split("_")[1],
					// topicTitle: this.title.split("_")[2],
					// title: this.title.split("_")[3]
					id:        this.title.split("_")[0],
					answEval1: -1,
					answEval2: this.amount_competence,
					title:     this.title.split("_")[1]
				}


				this.$emit('clickedDotSeq', clickEvent)
			}
		},

		updateAmount(selectedCircle) {

			// if we have a user profile, they probably want to fill out the concept
			if (this.type === 'code' && this.showConcept === true) {
				// Option 1:  Color until the circle which was selected by the user
				if (this.amount_concept != selectedCircle) {
					this.amount_concept = selectedCircle;
				} else {
					this.amount_concept = 0
				}
			}
			// if we have a referenceprofile, they probably want to fill out the the comptence

			if (this.type === 'profile') {
				// Option 1:  Color until the circle which was selected by the user
				if (this.amount_competence != selectedCircle) {
					this.amount_competence = selectedCircle;
				} else {
					this.amount_competence = 0
				}
			}

			// Option 2: Color always one at a time, no matter where the user clicked, if all circles are colored, start at the front again.
			//     if(this.amount_competence < this.length){
			//         this.amount_competence = this.amount_competence + 1;
			//     } else {
			//         this.amount_competence = 0;
			//     }
			//    console.log(this.amount_competence)
		},

	},
	// updated(){
	//   //console.log("Rerendering Dotsequence", this.competence)
	//     if(this.getUpdateValue !== -1){
	//         // We had a clicked Event
	//         this.amount_competence = this.getUpdateValue;
	//     } else{
	//         // we have a rerendering event due to dropdown menu switching
	//         this.amount_competence =  this.competence;
	//         this.amount_interests =  this.interests;
	//     }
	//  }
}
</script>

<style scoped>
.dotsequence {

	/*display: block; */
	white-space : nowrap;
}

.nameCol {

	text-align : left;
}

.dotCol {

	white-space   : nowrap;
	text-align    : left;
	padding-right : 20px;

}
</style>
